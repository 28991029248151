import React from "react";
import { Route, Routes } from "react-router-dom";

import ReserveList from "../views/page/reserve/ReserveList";
import VisitList from "../views/page/reserve/VisitList";
import CancelList from "../views/page/reserve/CancelList";
function RouterSetting() {
  return (
    <>
      <Routes>
        <Route exact path="/ReserveList" element={<ReserveList />} />
        <Route exact path="/VisitList" element={<VisitList />} />
        <Route exact path="/CancelList" element={<CancelList />} />
      </Routes>
    </>
  );
}

export default RouterSetting;