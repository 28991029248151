import React from "react";
import { NavLink } from "react-router-dom";

const SettingSubAdmin = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">부관리자 계정 관리</h2>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col width="20%"/><col/><col width="20%"/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>아이디</th>
        <th>이름</th>
        <th>관리</th>
      </tr>
      <tr>
        <td>5</td>
        <td>abc123</td>
        <td>김부관리자</td>
        <td>
          <NavLink to='/Setting/Manager/SubAdmin/Write' className="adm_com_btn blue line">수정</NavLink>
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td>abc123</td>
        <td>김부관리자</td>
        <td>
          <NavLink to='/Setting/Manager/SubAdmin/Write' className="adm_com_btn blue line">수정</NavLink>
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>abc123</td>
        <td>김부관리자</td>
        <td>
          <NavLink to='/Setting/Manager/SubAdmin/Write' className="adm_com_btn blue line">수정</NavLink>
        </td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap center">
      <NavLink to='/Setting/Manager/SubAdmin/Write' className="adm_com_btn blue">추가</NavLink>
    </div>
  </main>
  );
};

export default SettingSubAdmin;
