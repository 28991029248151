import React from "react";
import { NavLink } from "react-router-dom";
import Paging from "../../../component/Paging";

const PopupList = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">팝업 관리</h2>
    <div className="adm_com_btn_wrap right">
      <NavLink to='/Setting/Design/PopupWrite' className="adm_com_btn blue">등록하기</NavLink>
    </div>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col width="20%"/><col/><col width="20%"/><col width="20%"/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>팝업명</th>
        <th>게시시간</th>
        <th>사용여부</th>
        <th>관리</th>
      </tr>
      <tr>
        <td>5</td>
        <td>팝업명이 들어가는 영역</td>
        <td>2021-08-01 ~ 2021-08-30</td>
        <td>
          <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
          <label htmlFor="chk01">Y</label>
        </td>
        <td>
          <NavLink to='/Setting/Design/PopupList/Write' className="adm_com_btn blue line">수정</NavLink>
          <button className="adm_com_btn gray line">삭제</button>
        </td>
      </tr>
    </table>
    <Paging />
  </main>
  );
};

export default PopupList;
