import React,{useState} from "react";
import Modal from "../../component/Modal";

const PointProduct = () => {
  const [productAdd, setProductAdd] = useState(false);
  return (
  <main>
    <h2 className="adm_com_pg_tit">포인트 상품 관리</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_btn_wrap right">
        <button onClick={() => {setProductAdd(true)}} className="adm_com_btn blue">상품추가</button>
      </div>

      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
          <col width="10%"/>
        </colgroup>
        <tbody>
        <tr>
          <th>번호</th>
          <th>상품명</th>
          <th>상품금액</th>
          <th>보너스 금액</th>
          <th>총 충전금액</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>포인트 상품5</td>
          <td>329,000원</td>
          <td>9,000P</td>
          <td>119,000P</td>
          <td>
            <button className="adm_com_btn blue line">수정</button>
            <button className="adm_com_btn gray line">삭제</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <Modal open={productAdd} close={() => setProductAdd(false)} popSize="s">
      <div className="pop_tit">상품추가</div>
      <div className="content_container">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="25%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>상품명</th>
              <td><input type="text" defaultValue={`바디슈링크 500샷`}/></td>
            </tr>
            <tr>
              <th>상품금액</th>
              <td><input type="text" defaultValue={`100,000`}/></td>
            </tr>
            <tr>
              <th>보너스 금액</th>
              <td><input type="text" defaultValue={`10,000`}/></td>
            </tr>
            <tr>
              <th>총 충전금액</th>
              <td>110,000P</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button className="adm_com_btn blue">등록</button>
        <button onClick={() => setProductAdd(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
  </main>
  );
};

export default PointProduct;
