import React from "react";
const ProductReviewDetail = () => {
  return (
    <main>
      <h2 className="adm_com_pg_tit">시술후기 상세</h2>
      <div className="adm_com_pg_content">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="10%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>김올리/여 
                <input type="checkbox" id="chk01" className="adm_com_chk" />
                <label htmlFor="chk01">노출여부</label>
              </td>
            </tr>
            <tr>
              <th>닉네임</th>
              <td>올리5</td>
            </tr>
            <tr>
              <th>휴대폰번호</th>
              <td>010-5555-4444</td>
            </tr>
            <tr>
              <th>예약완료일자</th>
              <td>2021-08-10</td>
            </tr>
            <tr>
              <th rowSpan={3}>시술명</th>
              <td>주름보톡스</td>
            </tr>
            <tr>
              <td>코필러/슈퍼미쥬코</td>
            </tr>
            <tr>
              <td>내맘대로 초이스 슈링크</td>
            </tr>
            <tr>
              <th>내용</th>
              <td>너무 마음에 들어요. 만족스럽습니다.</td>
            </tr>
            <tr>
              <th>시술전 이미지</th>
              <td>
                <p><button>attachimg.jpg</button></p>
                <p><button>attachimg.jpg</button></p>
              </td>
            </tr>
            <tr>
              <th>시술후 이미지</th>
              <td>
                <p><button>attachimg.jpg</button></p>
                <p><button>attachimg.jpg</button></p>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn blue">등록</button>
          <button className="adm_com_btn gray">취소</button>
        </div>
      </div>
    </main>
  )
};

export default ProductReviewDetail;
