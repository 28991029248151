import React from "react";
import { NavLink } from "react-router-dom";
import Paging from '../../component/Paging'

const Review = () => {
  return (
    <main>
      <h2 className="adm_com_pg_tit">칭찬불만 목록</h2>
      <div className="adm_com_pg_content">
        <div className="adm_com_board_list__top">
          <div className="adm_com_date_select">
            <span className="tit">기간설정 : </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <span className="divider"> ~ </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <button className="adm_com_btn black">오늘</button>
            <button className="adm_com_btn gray">어제</button>
            <button className="adm_com_btn gray">일주일</button>
            <button className="adm_com_btn gray">이번달</button>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">칭찬/불만 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">유형 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">상세조회 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
            <article className='search'>
              <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
              <button className="adm_com_btn blue">조회</button>
              <button className="adm_com_btn gray">초기화</button>
            </article>
          </div>
        </div>
        <div className="adm_com_btn_wrap">
          <button className="adm_com_btn gray">선택삭제</button>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="5%"/><col width="5%"/><col width="5%"/><col width="10%"/>
            <col width="20%"/><col width="5%"/><col width="5%"/><col width="10%"/>
          </colgroup>
          <thead>
            <tr>
              <th>
                <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
                <label htmlFor="chk01"></label>
              </th>
              <th>번호</th>
              <th>칭찬/불만</th>
              <th>유형</th>
              <th>내용</th>
              <th>이름</th>
              <th>닉네임</th>
              <th>등록일자</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
                <label htmlFor="chk01"></label>
              </td>
              <td>5</td>
              <td>칭찬</td>
              <td>직원에 대한 의견</td>
              <td><NavLink to="/Board/Manage/Review/Detail">직원분이 친절하셔서 칭찬하고싶어요</NavLink></td>
              <td>김올리</td>
              <td>올리5</td>
              <td>2023-12-12</td>
            </tr>
          </tbody>
        </table>
        <Paging />
      </div>
    </main>
  )
};

export default Review;
