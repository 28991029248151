import React from "react";
import { Route, Routes } from "react-router-dom";

import MemberManage from "../views/page/member/MemberManage";
import MemberDetail from "../views/page/member/MemberDetail";
import MemberLeave from "../views/page/member/MemberLeave";
function RouterMember() {
  return (
    <>
      <Routes>
        <Route exact path="/MemberManage" element={<MemberManage />} />
        <Route exact path="/MemberManage/Detail" element={<MemberDetail />} />
        <Route exact path="/MemberLeave" element={<MemberLeave />} />
      </Routes>
    </>
  );
}

export default RouterMember;