import React,{useState} from "react";
import Modal from "../../component/Modal";

const RecommendWrite = () => {
  const [preview, setPreview] = useState(false);
  return (
  <main>
    <h2 className="adm_com_pg_tit">추천시술 변경</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_board_list__top">
        <div className="adm_com_input_wrap flex_row">
          <span className="tit">카테고리 </span>
          <select className="select">
            <option value="일반">일반</option>
          </select>
          <select className="select">
            <option value="리프팅">리프팅</option>
          </select>
          <select className="select">
            <option value="바디슈링크">바디슈링크</option>
          </select>
        </div>
        <article className='adm_com_input_wrap flex_row'>
          <span className="tit">검색 </span>
          <div className="adm_com_board_search">
            <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input type_search" />
            <button className="adm_com_btn blue">조회</button>
            <button className="adm_com_btn gray">초기화</button>
          </div>
        </article>
      </div>
      <div className="adm_com_input_wrap flex_row flex_s">
        <div className="list_box">
          <h3 className="adm_com_pg_tit__wrap margin_top">검색결과</h3>
          <table className='adm_com_board_list scroll'>
            <colgroup>
              <col width="20%"/><col width="10%"/><col width="10%"/>
            </colgroup>
            <tr>
              <th>패키지명</th>
              <th>표준가격</th>
              <th>할인가격</th>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
          </table>
        </div>
        <button className="btn_add">&gt;&gt;</button>
        <div className="list_box">
          <h3 className="adm_com_pg_tit__wrap margin_top">선택한 시술</h3>
          <table className='adm_com_board_list scroll'>
            <colgroup>
              <col width="20%"/><col width="10%"/><col width="10%"/><col width="10%"/>
            </colgroup>
            <tr>
              <th>패키지명</th>
              <th>표준가격</th>
              <th>할인가격</th>
              <th>관리</th>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
          </table>
        </div>
      </div>
      <div className="adm_com_btn_wrap bottom center">
        <button className="adm_com_btn blue">저장</button>
        <button className="adm_com_btn gray">취소</button>
        <button className="adm_com_btn black right" onClick={() => {setPreview(true)}}>미리보기</button>
      </div>
    </div>

    <Modal open={preview} close={() => setPreview(false)} popSize="l">
      <div className="pop_tit">미리보기</div>
      <div className="content_container">
        미리보기
      </div>
    </Modal>
  </main>
  );
};

export default RecommendWrite;
