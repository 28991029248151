import React from "react";

const Privacy = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">개인정보처리방침</h2>
    <div className="adm_com_pg_content">
    개인정보처리방침 내용이 나오는 영역
    </div>
  </main>
  );
};

export default Privacy;
