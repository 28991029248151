import React from "react";

const PushWrite = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">자동 PUSH 관리</h2>
    <table className='adm_com_board_list type_row'>
      <colgroup>
        <col width="10%"/><col/>
      </colgroup>
      <tr>
        <th>메세지 발송 상황</th>
        <td>예약완료</td>
      </tr>
      <tr>
        <th>사용여부</th>
        <td>
          <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
          <label htmlFor="chk01">Y</label>
        </td>
      </tr>
      <tr>
        <th>내용</th>
        <td>
          <textarea>[패키지 명] 예약이 되었습니다. 마이페이지에서 확인해보세요.</textarea>
        </td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap center">
      <button className="adm_com_btn blue">수정</button>
      <button className="adm_com_btn gray">취소</button>
    </div>
  </main>
  );
};

export default PushWrite;
