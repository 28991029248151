import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import Paging from "../../component/Paging";
import Modal from "../../component/Modal";
import Chat from "../../component/Chat";

const MemberDetail = () => {
  const [pointChange, setPointChange] = useState(false)
  const [chattingView, setChattingView] = useState(false)
  return (
  <main>
    <h2 className="adm_com_pg_tit">회원 관리</h2>
    <h3 className="adm_com_pg_tit__wrap margin_top">- 회원 상세정보
      <div className="adm_com_btn_wrap right">
        <button className="adm_com_btn gray s">수정</button>
        <NavLink to="/Member/MemberManage" className="adm_com_btn blue s">목록</NavLink>
      </div>
    </h3>
    <table className='adm_com_board_list type_row'>
      <colgroup>
        <col width="10%"/><col width="40%"/>
        <col width="10%"/><col width="40%"/>
      </colgroup>
      <tr>
        <th>아이디</th>
        <td>abc123</td>
        <th>비밀번호</th>
        <td><input type="text" className="input" /></td>
      </tr>
      <tr>
        <th>이름</th>
        <td>김올리</td>
        <th>성별</th>
        <td>
          <div className="adm_com_input_wrap">
            <input type="checkbox" className="adm_com_chk circle" id="chk01" name="chk01" value="Y" />
            <label htmlFor="chk01">여자</label>
            <input type="checkbox" className="adm_com_chk circle" id="chk02" name="chk01" value="Y" />
            <label htmlFor="chk02">남자</label>
          </div>
        </td>
      </tr>
      <tr>
        <th>닉네임</th>
        <td>김올리34</td>
        <th>생년월일</th>
        <td>
          <select className="select ws">
            <option value="1991">1991</option>
          </select>
          <select className="select ws">
            <option value="01">01</option>
          </select>
          <select className="select ws">
            <option value="01">01</option>
          </select>
        </td>
      </tr>
      <tr>
        <th>휴대폰</th>
        <td colSpan={3}><input type="text" className="input wl" /></td>
      </tr>
      <tr>
        <th>이메일</th>
        <td colSpan={3}><input type="text" className="input wl" /></td>
      </tr>
      <tr>
        <th>관심부위</th>
        <td colSpan={3}>눈,코,입</td>
      </tr>
      <tr>
        <th>최근접속일</th>
        <td colSpan={3}>2021-08-19 16:30:30</td>
      </tr>
      <tr>
        <th>출석횟수</th>
        <td colSpan={3}>50 (4)</td>
      </tr>
      <tr>
        <th>가입일자</th>
        <td>2021-08-19 08:30:45</td>
        <th>탈퇴일자</th>
        <td>-</td>
      </tr>
      <tr>
        <th>1:1 채팅내역</th>
        <td colSpan={3}><button className="adm_com_btn blue line" onClick={() => {setChattingView(true)}}>채팅보기</button></td>
      </tr>
      <tr>
        <th>보유포인트</th>
        <td colSpan={3}>
          142,000 P &nbsp;<span className="adm_com_red_txt">(8,000 P)</span> &nbsp;
          <button className="adm_com_btn blue line" onClick={() => {setPointChange(true)}}>포인트 변동하기</button>
        </td>
      </tr>
    </table>

    <h3 className="adm_com_pg_tit__wrap margin_top">- 포인트 내역</h3>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>상품명</th>
        <th>충전 금액</th>
        <th>차감 금액</th>
        <th>포인트 잔액</th>
        <th>변동일자</th>
      </tr>
      <tr>
        <td>5</td>
        <td>바디인모드</td>
        <td>-</td>
        <td>150,000P</td>
        <td>0P</td>
        <td>2021-09-02</td>
      </tr>
    </table>
    <Paging />

    <h3 className="adm_com_pg_tit__wrap margin_top">- 쿠폰 내역</h3>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>수령일자</th>
        <th>유효기간</th>
        <th>쿠폰명</th>
        <th>사용처</th>
        <th>쿠폰금액</th>
        <th>상태</th>
        <th>사용일자</th>
      </tr>
      <tr>
        <td>5</td>
        <td>2021-09-02</td>
        <td>2021-09-02</td>
        <td>생일축하쿠폰</td>
        <td>리프팅</td>
        <td>30,000원</td>
        <td>보유</td>
        <td>2021-09-02 15:20</td>
      </tr>
    </table>
    <Paging />

    <h3 className="adm_com_pg_tit__wrap margin_top">- 현금영수증 신청내역</h3>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>상품명</th>
        <th>결제금액</th>
        <th>현금영수증신청</th>
        <th>현금영수증종류</th>
        <th>현금영수증번호</th>
      </tr>
      <tr>
        <td>5</td>
        <td>포인트 상품 5</td>
        <td>30,000원</td>
        <td>개인</td>
        <td>핸드폰번호</td>
        <td>01024565454</td>
      </tr>
    </table>
    <Paging />

    <Modal open={pointChange} close={() => setPointChange(false)} popSize="m">
      <div className="pop_tit">포인트 변동하기</div>
      <div className="content_container">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="20%"/><col/>
          </colgroup>
          <tbody>
          <tr>
            <th>종류</th>
            <td>    
              <div className="adm_com_input_wrap">
                <input type="checkbox" className="adm_com_chk circle" id="point01" name="chk_point" value="Y" />
                <label htmlFor="point01">충전</label>
                <input type="checkbox" className="adm_com_chk circle" id="point02" name="chk_point" value="Y" />
                <label htmlFor="point02">차감</label>
              </div>
            </td>
          </tr>
          <tr>
            <th>금액</th>
            <td><input type="text" className="input" defaultValue={'30,000'}/></td>
          </tr>
          <tr>
            <th>변동내용</th>
            <td>
              <textarea className="textarea">포인트 환불</textarea>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button className="adm_com_btn blue">확인</button>
        <button onClick={() => setPointChange(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
    <Modal open={chattingView} close={() => setChattingView(false)} popSize="m">
      <Chat />
      <div className="btn_container">
        <button onClick={() => setChattingView(false)} className="adm_com_btn s gray">닫기</button>
      </div>
    </Modal>
  </main>
  );
};

export default MemberDetail;
