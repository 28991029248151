import React from 'react';

function Chat() {
  return (
    <section className="chat_sec">
      <article className="profile_box">
        <div className="info">
          <p className="name"><b>홍길동</b></p>
        </div>
        {/* <button className="btn_menu" onClick={() => setModalAlert(!modalAlert)}></button> */}
      </article>
      {/*채팅내용 스크롤*/}
      <div className="scroll_body scroll">
        {/* <p className='date_area'><span>2023.5.30</span></p> */}

        <article className="send">
          <div className="wrap">
            <p className="date">2023.05.11 18:30</p>
            <div className="message_box">
              시술문의 합니다.
            </div>
          </div>
        </article>
        <article className="get">
          {/* <p className="img">
            <img src={ImgProfile} alt="프로필이미지" />
          </p> */}
          <div className="wrap">
            <p className="name_field"><span className="date">2023.05.11 18:30</span></p>
            <div className="message_box">
            안녕하세요. 홍길동님, 올리프트 1:1 채팅 상담센터입니다.
            </div>
            <div className="message_box">
            잠시 후 상담사와 연결됩니다.시간이 소요될 수 있으니 잠시만 기다려주세요.
            </div>
            <div className="message_box">
            안녕하세요! 상담원 김미나입니다! 어떤 부분이 궁금하신가요~~?
            </div>
          </div>
        </article>
        <article className="send">
          <div className="wrap">
            <p className="date">2023.05.11 18:30</p>
            <div className="message_box">
              안녕하세요.<br/>
              만나서 반갑습니다.
            </div>
          </div>
        </article>
        <article className="get">
          {/* <p className="img">
            <img src={ImgProfile} alt="프로필이미지" />
          </p> */}
          <div className="wrap">
            <p className="name_field"><span className="date">2023.05.11 18:30</span></p>
            <div className="message_box">
              안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요안녕하세요
            </div>
          </div>
        </article>
        <article className="send">
          <div className="wrap">
            <p className="date">2023.05.11 18:30</p>
            <div className="message_box">
              안녕하세요.<br/>
              만나서 반갑습니다.안녕하세요.<br/>
              만나서 반갑습니다.안녕하세요.<br/>
              만나서 반갑습니다.안녕하세요.<br/>
              만나서 반갑습니다.안녕하세요.<br/>
              만나서 반갑습니다.
            </div>
          </div>
        </article>
        <article className="get">
          {/* <p className="img">
            <img src={ImgProfile} alt="프로필이미지" />
          </p> */}
          <div className="wrap">
            <p className="name_field"><span className="date">2023.05.11 18:30</span></p>
            <div className="message_box">
              안녕하세요
            </div>
          </div>
        </article>
        <article className="send">
          <div className="wrap">
            <p className="date">2023.05.11 18:30</p>
            <div className="message_box">
              안녕하세요.<br/>
              만나서 반갑습니다.
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

export default Chat;
