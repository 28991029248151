import React from "react";

const AccountList = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">계좌 관리</h2>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col width="20%"/><col/><col width="20%"/><col width="10%"/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>은행명</th>
        <th>계좌번호</th>
        <th>예금주</th>
        <th>관리</th>
      </tr>
      <tr>
        <td>5</td>
        <td><input type="text" className="input" /></td>
        <td><input type="text" className="input" /></td>
        <td>
          <input type="text" className="input" />
        </td>
        <td>
          <button className="adm_com_btn gray line">삭제</button>
        </td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap center">
      <button className="adm_com_btn blue">추가</button>
      <button className="adm_com_btn gray">저장</button>
    </div>
  </main>
  );
};

export default AccountList;
