import React, { useState } from "react";

const Dashboard = () => {
  const [activeId, setActiveId] = useState(null);

  const handleToggle = (id) => {
    setActiveId(activeId === id ? null : id);
  };
  const hotels = [
    {
      id: 1,
      name: "Luxury Resort",
      location: "Beachside",
      price: "$300",
      description:
        "Experience luxury and comfort by the beach at our exquisite resort.",
    },
    {
      id: 2,
      name: "Cozy Cabin Retreat",
      location: "Mountain Area",
      price: "$150",
      description:
        "Escape to the mountains and enjoy a cozy cabin experience with stunning views.",
    },
    {
      id: 3,
      name: "Urban Boutique Hotel",
      location: "City Center",
      price: "$250",
      description:
        "Stay in style in the heart of the city at our trendy boutique hotel.",
    },
  ];

  return (
    <main>
      <h2 className="adm_com_pg_tit">Dashboard</h2>
      <div className="adm_com_pg_content">
        <table className="adm_com_board_list">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Hotel Name</th>
              <th scope="col">Location</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody>
            {hotels.map((hotel) => (
              <React.Fragment key={hotel.id}>
                <tr onClick={() => handleToggle(hotel.id)}>
                  <th scope="row">
                    {hotel.id}{" "}
                    <i
                      className={`bi bi-chevron-${
                        activeId === hotel.id ? "up" : "down"
                      }`}
                    ></i>
                  </th>
                  <td>
                    {hotel.name}
                  </td>
                  <td>{hotel.location}</td>
                  <td>{hotel.price}</td>
                </tr>
                {activeId === hotel.id && (
                  <tr>
                    <td colSpan="4" className="td_left bg_col">
                      {hotel.description}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default Dashboard;
