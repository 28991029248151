import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "../assets/scss/styles.scss";

import RouterSetting from "./router.setting";
import RouterMember from "./router.member";
import RouterBoard from "./router.board";
import RouterProduct from "./router.product";
import RouterReserve from "./router.reserve";
import RouterPayment from "./router.payment";

import Dashboard from "../views/Dashboard";
import About from "../views/About";
import Login from "../views/page/member/Login";


function Routers() {
  const pathName = useLocation().pathname;
  return (
    <div className={`${
      pathName.includes("/Login") ? `` : `adm_com_container`
    }`}>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/Setting/*" element={<RouterSetting />} />
        <Route exact path="/Member/*" element={<RouterMember />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/Product/*" element={<RouterProduct />} />
        <Route exact path="/Reserve/*" element={<RouterReserve />} />
        <Route exact path="/Board/*" element={<RouterBoard />} />
        <Route exact path="/Payment/*" element={<RouterPayment />} />
      </Routes>
    </div>
  );
}

export default Routers;
