import React, { useState } from "react";
import Modal from "../../../component/Modal";

const CouponIssue = () => {
  const [couponAdd, setCouponAdd] = useState(false)
  const [couponNew, setCouponNew] = useState(false)
  return (
  <main>
    <h2 className="adm_com_pg_tit">쿠폰관리 및 발행</h2>
    <div className="adm_com_pg_content">
      <h3 className="adm_com_pg_tit__wrap margin_top">- 자동발행 쿠폰 관리</h3>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
        </colgroup>
        <tr>
          <th>번호</th>
          <th>쿠폰종류</th>
          <th>사용처</th>
          <th>할인금액</th>
          <th>유효기간</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>출석12회 쿠폰</td>
          <td>전체</td>
          <td>30,000원</td>
          <td>30일</td>
          <td><button className="adm_com_btn blue line">수정</button></td>
        </tr>
      </table>
      <h3 className="adm_com_pg_tit__wrap margin_top">- 일반시술 쿠폰 관리
        <div className="adm_com_btn_wrap right">
          <button className="adm_com_btn gray">선택삭제</button>
          <button className="adm_com_btn blue" onClick={() => {setCouponAdd(true)}}>쿠폰추가</button>
        </div>
      </h3>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
        </colgroup>
        <tr>
          <th>번호</th>
          <th>쿠폰종류</th>
          <th>사용처</th>
          <th>할인금액</th>
          <th>유효기간</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>일반 쿠폰</td>
          <td>전체</td>
          <td>30,000원</td>
          <td>30일</td>
          <td>
            <button className="adm_com_btn blue line">수정</button>
            <button className="adm_com_btn red line" onClick={() => {setCouponNew(true)}}>발행</button>
          </td>
        </tr>
      </table>
      <h3 className="adm_com_pg_tit__wrap margin_top">- 이벤트시술 쿠폰 관리
        <div className="adm_com_btn_wrap right">
          <button className="adm_com_btn gray">선택삭제</button>
          <button className="adm_com_btn blue" onClick={() => {setCouponAdd(true)}}>쿠폰추가</button>
        </div>
      </h3>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
        </colgroup>
        <tr>
          <th>번호</th>
          <th>쿠폰종류</th>
          <th>사용처</th>
          <th>할인금액</th>
          <th>유효기간</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>8월 이벤트 쿠폰</td>
          <td>전체</td>
          <td>30,000원</td>
          <td>30일</td>
          <td>
            <button className="adm_com_btn blue line">수정</button>
            <button className="adm_com_btn red line" onClick={() => {setCouponNew(true)}}>발행</button>
          </td>
        </tr>
      </table>
    </div>

    <Modal open={couponAdd} close={() => setCouponAdd(false)} popSize="s">
      <div className="pop_tit">쿠폰 등록</div>
      <div className="content_container">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="20%"/><col/>
          </colgroup>
          <tbody>
          <tr>
            <th>쿠폰종류</th>
            <td><input type="text" className="input" defaultValue={'일반 쿠폰'}/></td>
          </tr>
          <tr>
            <th>사용처</th>
            <td>
              <select className="select ws">
                <option value="선택">선택</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>할인금액</th>
            <td><input type="text" className="input" defaultValue={'30,000'}/></td>
          </tr>
          <tr>
            <th>유효기간</th>
            <td><input type="text" className="input ws" /> 일</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button onClick={() => setCouponAdd(false)} className="adm_com_btn blue">등록</button>
        <button onClick={() => setCouponAdd(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
    <Modal open={couponNew} close={() => setCouponNew(false)} popSize="l">
      <div className="pop_tit">쿠폰 발행</div>
      <div className="content_container">
        <div className="adm_com_board_list__top">
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">상세조회 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
            <article className='search'>
              <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input type_search" />
              <button className="adm_com_btn blue">조회</button>
              <button className="adm_com_btn gray">초기화</button>
            </article>
          </div>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
            <col width="10%"/><col width="10%"/><col width="10%"/><col width="5%"/>
          </colgroup>
          <tbody>
          <tr>
            <th>번호</th>
            <th>아이디</th>
            <th>이름</th>
            <th>닉네임</th>
            <th>휴대폰</th>
            <th>이메일</th>
            <th>가입일</th>
            <th>최근접속일</th>
            <th><input type="checkbox" className="adm_com_chk" id="chk" name="chk" /><label htmlFor="chk"></label></th>
          </tr>
          <tr>
            <td>5</td>
            <td>abc1233</td>
            <td>김올리</td>
            <td>올리5</td>
            <td>01012451245</td>
            <td>abc1233@naver.com</td>
            <td>2023-01-01</td>
            <td>2023-01-01</td>
            <td><input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" /><label htmlFor="chk01"></label></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button className="adm_com_btn blue" onClick={() => {setCouponNew(true)}}>발행</button>
        <button onClick={() => setCouponNew(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
  </main>
  );
};

export default CouponIssue;
