import React, { useRef} from "react";

const PictureWrite = () => {
  const uploadInput = useRef();
  return (
    <main>
      <h2 className="adm_com_pg_tit">전후사진 등록</h2>
      <div className="adm_com_pg_content">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="10%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>카테고리</th>
              <td>
                <select className="select ws">
                  <option value="전체">전체</option>
                </select>
                <input type="checkbox" id="chk02" className="adm_com_chk" />
                <label htmlFor="chk02">노출여부</label>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td><input type="text" className="input" /></td>
            </tr>
            <tr>
              <th>시술전 이미지</th>
              <td>
                <div className="btn_attach">
                  <input type="file" className="input_file" ref={uploadInput} />
                </div>
              </td>
            </tr>
            <tr>
              <th>시술후 이미지</th>
              <td>
                <div className="btn_attach">
                  <input type="file" className="input_file" ref={uploadInput} />
                </div>
              </td>
            </tr>
            <tr>
              <th>시술전 일자</th>
              <td><input type="text" className="input ws" /></td>
            </tr>
            <tr>
              <th>시술후 일자</th>
              <td><input type="text" className="input ws" /></td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <div className="editor">editor</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn blue">등록</button>
          <button className="adm_com_btn gray">취소</button>
        </div>
      </div>
    </main>
  )
};

export default PictureWrite;
