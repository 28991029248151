import React from "react";

const PopupWrite = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">팝업 관리</h2>
    <table className='adm_com_board_list type_row'>
      <colgroup>
        <col width="10%"/><col/>
      </colgroup>
      <tr>
        <th>팝업명</th>
        <td><input type='text' className="input"/></td>
      </tr>
      <tr>
        <th>게시기간</th>
        <td>
          <div className="adm_com_date_select">
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <span className="divider"> ~ </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          </div>
        </td>
      </tr>
      <tr>
        <th>위치 및 크기 설정</th>
        <td>
          <div className="input_wrap">상단 간격 &nbsp;<input type="text" className="input ws" /> &nbsp;왼쪽 간격</div>
          <div className="input_wrap">팝업 높이 &nbsp;<input type="text" className="input ws" /> &nbsp;팝업 너비</div>
        </td>
      </tr>
      <tr>
        <th>팝업형식</th>
        <td>
          <select className="select ws">
            <option value="">새창</option>
          </select>
        </td>
      </tr>
      <tr>
        <th>사용여부</th>
        <td>
          <select className="select ws">
            <option value="">Y</option>
          </select>
        </td>
      </tr>
      <tr>
        <th>내용</th>
        <td><div className="editor"></div></td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap center">
      <button className="adm_com_btn blue">등록</button>
      <button className="adm_com_btn gray">취소</button>
    </div>
  </main>
  );
};

export default PopupWrite;
