import React, {useState, useRef} from "react";
import Paging from "../../component/Paging";
import Modal from "../../component/Modal";
import { NavLink } from "react-router-dom";

const EventPackage = () => {
  const [packageAdd, setPackageAdd] = useState(false);
  const uploadInput = useRef();
  const fnUpload = () => {
    uploadInput.current.click();
  };
  return (
  <main>
    <h2 className="adm_com_pg_tit">이벤트 패키지 목록</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_board_list__top">
        <div className="adm_com_board_search">
          <div className="adm_com_input_wrap flex_row">
            <span className="tit">카테고리 : </span>
            <select className="select">
              <option value="전체">전체</option>
            </select>
            <span className="tit">승인상태 : </span>
            <select className="select">
              <option value="전체">전체</option>
            </select>
          </div>
          <article className='search end'>
            <input type="search" placeholder='시술명을 입력해 주세요.' className="input ws type_search" />
            <button className="adm_com_btn blue">조회</button>
            <button className="adm_com_btn gray">초기화</button>
          </article>
        </div>
      </div>
      <div className="adm_com_btn_wrap right">
        <button className="adm_com_btn gray" onClick={() => {setPackageAdd(true)}}>엑셀추가</button>
        <button className="adm_com_btn blue">추가</button>
      </div>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
          <col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
        </colgroup>
        <tbody>
        <tr>
          <th>번호</th>
          <th>이벤트기간</th>
          <th>카테고리</th>
          <th>패키지명</th>
          <th>이벤트/일반/옵션</th>
          <th>최근수정일</th>
          <th>승인상태</th>
          <th>노출여부</th>
          <th>업셀링</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>2020-08-19 ~ 2021-08-19</td>
          <td>[이벤트]이럴떄 뭐하지?</td>
          <td>트러블? 가리지말고 해결하자!</td>
          <td>1/2/2</td>
          <td>2023-01-01</td>
          <td>
            <button className="adm_com_btn gray line">승인요청</button>
            <button className="adm_com_btn blue line">승인</button>
            <button className="adm_com_btn gray line">거절</button>  
          </td>
          <td>
            <div className="check">
              <input type="checkbox" id="chk_show" className="adm_com_chk" />
              <label htmlFor="chk_show">Y</label>
            </div>
          </td>
          <td>
            <div className="check">
              <input type="checkbox" id="chk01" className="adm_com_chk" />
              <label htmlFor="chk01"/>
            </div>
          </td>
          <td>
            <NavLink to="/Product/Category/EventPackage/Write" className="adm_com_btn blue line">수정</NavLink>
            <button className="adm_com_btn black line">보기</button>
          </td>
        </tr>
        <tr>
          <td>4</td>
          <td>2020-08-19 ~ 2021-08-19</td>
          <td>[이벤트]이럴떄 뭐하지?</td>
          <td>트러블? 가리지말고 해결하자!</td>
          <td>1/2/2</td>
          <td>2023-01-01</td>
          <td className="adm_com_red_txt">승인거절</td>
          <td>
            <div className="check">
              <input type="checkbox" id="chk_show" className="adm_com_chk" />
              <label htmlFor="chk_show">Y</label>
            </div>
          </td>
          <td>
            <div className="check">
              <input type="checkbox" id="chk01" className="adm_com_chk" />
              <label htmlFor="chk01"/>
            </div>
          </td>
          <td>
            <NavLink to="/Product/Category/EventPackage/Write" className="adm_com_btn blue line">수정</NavLink>
            <button className="adm_com_btn black line">보기</button>
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>2020-08-19 ~ 2021-08-19</td>
          <td>[이벤트]이럴떄 뭐하지?</td>
          <td>트러블? 가리지말고 해결하자!</td>
          <td>1/2/2</td>
          <td>2023-01-01</td>
          <td className="adm_com_blue_txt">승인완료</td>
          <td>
            <div className="check">
              <input type="checkbox" id="chk_show" className="adm_com_chk" />
              <label htmlFor="chk_show">Y</label>
            </div>
          </td>
          <td>
            <div className="check">
              <input type="checkbox" id="chk01" className="adm_com_chk" />
              <label htmlFor="chk01"/>
            </div>
          </td>
          <td>
            <NavLink to="/Product/Category/EventPackage/Write" className="adm_com_btn blue line">수정</NavLink>
            <button className="adm_com_btn black line">보기</button>
          </td>
        </tr>
        </tbody>
      </table>
      <Paging />
    </div>
    <Modal open={packageAdd} close={() => setPackageAdd(false)} popSize="s">
      <div className="pop_tit">이벤트 패키지 등록</div>
      <div className="content_container">
        <p className="pop_desc">일괄 등록할 이벤트 패키지의<br />엑셀을 첨부해주세요.</p>
        <div className="btn_attach">
          <input type="file" className="input_file" ref={uploadInput} />
          <button onClick={fnUpload} className="adm_com_btn gray">
            첨부하기
          </button>
        </div>
      </div>
      <div className="btn_container">
        <button className="adm_com_btn blue">등록</button>
        <button onClick={() => setPackageAdd(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
  </main>
  );
};

export default EventPackage;
