import React from "react";

const SettingManager = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">관리자 계정 관리</h2>
    <table className='adm_com_board_list type_row'>
      <colgroup>
        <col width="10%"/><col/>
      </colgroup>
      <tr>
        <th>아이디</th>
        <td><input type='text' className="input"/></td>
      </tr>
      <tr>
        <th>이름</th>
        <td><input type='text' className="input"/></td>
      </tr>
      <tr>
        <th>비밀번호</th>
        <td><input type='password' className="input"/></td>
      </tr>
      <tr>
        <th>비밀번호 재설정</th>
        <td><input type='password' className="input"/></td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap center">
      <button className="adm_com_btn blue">수정</button>
    </div>
  </main>
  );
};

export default SettingManager;
