import React, { useState } from "react";
import Paging from "../../component/Paging";
import Modal from "../../component/Modal";

const CancelList = () => {
  const [reserveEdit, setReserveEdit] = useState(false);
  const [reserveMemo, setReserveMemo] = useState(false);
  return (
  <main>
    <h2 className="adm_com_pg_tit">예약취소</h2>
    <div className="adm_com_pg_content">
      <h3 className="adm_com_pg_tit__wrap margin_top">
        - 예약 목록
      </h3>
      <div className="adm_com_board_list__top">
        <div className="adm_com_date_select">
          <span className="tit">기간설정 : </span>
          <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          <span className="divider"> ~ </span>
          <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          <button className="adm_com_btn black">오늘</button>
          <button className="adm_com_btn gray">어제</button>
          <button className="adm_com_btn gray">일주일</button>
          <button className="adm_com_btn gray">이번달</button>
        </div>
        <div className="adm_com_board_search">
          <div className="adm_com_input_wrap flex_row">
            <span className="tit">상세조회 : </span>
            <select className="select">
              <option value="전체">전체</option>
              <option value="시술명">시술명</option>
              <option value="메모/요청사항 내용">메모/요청사항 내용</option>
              <option value="이름">이름</option>
            </select>
          </div>
          <article className='search'>
            <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
            <button className="adm_com_btn blue">조회</button>
            <button className="adm_com_btn gray">초기화</button>
          </article>
        </div>
      </div>
      <div className="adm_com_board_list__option">
        <select className="select">
          <option>예약신청일순</option>
          <option>예약완료순</option>
        </select>
      </div>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="3%"/><col width="7%"/><col width="8%"/><col width="8%"/><col width="20%" />
          <col width="8%"/><col width="8%"/><col width="8%"/><col width="8%"/><col width="8%"/><col width="20%"/>
        </colgroup>
        <tbody>
        <tr>
          <th>번호</th>
          <th>이름</th>
          <th>휴대폰</th>
          <th>예약신청일자</th>
          <th>예약시술명</th>
          <th>예약취소일자</th>
          <th>쿠폰사용 여부</th>
          <th>총 금액</th>
          <th>CALL</th>
          <th>예약상태</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>김올리</td>
          <td>01012451245</td>
          <td>2021-08-21 15:30</td>
          <td>모공보톡스 1부위 외 2건</td>
          <td>2024-01-01</td>
          <td>미사용</td>
          <td>2,000,000원</td>
          <td>
            <select className="select">
              <option>선택</option>
            </select>
          </td>
          <td>
            <select className="select">
              <option>예약신청완료</option>
              <option>예약완료</option>
              <option>예약완료</option>
              <option>예약취소</option>
            </select>
          </td>
          <td>
            <button className="adm_com_btn line blue">저장</button>
            <button className="adm_com_btn line gray" onClick={()=> setReserveEdit(true)}>수정</button>
            <button className="adm_com_btn line gray" onClick={()=> setReserveMemo(true)}>메모</button>
          </td>
        </tr>
        </tbody>
      </table>
      <Paging />
    </div>
    <Modal open={reserveEdit} close={() => setReserveEdit(false)} popSize="s">
      <div className="pop_tit">예약 수정</div>
      <div className="content_container">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="25%"/><col/>
          </colgroup>
          <tbody>
          <tr>
            <th>이름</th>
            <td><input type="text" className="input" defaultValue={'김올리'}/></td>
          </tr>
          <tr>
            <th>휴대폰번호</th>
            <td><input type="text" className="input" defaultValue={'010-0000-0000'}/></td>
          </tr>
          <tr>
            <th>예약일자</th>
            <td><input type="text" className="input" defaultValue={'2024.01.01'}/></td>
          </tr>
          <tr>
            <th>예약시간</th>
            <td><input type="text" className="input" defaultValue={'16:00'}/></td>
          </tr>
          <tr>
            <th>상담요청여부</th>
            <td>
              <input type="radio" name="radio1" id="radio11" className="adm_com_chk circle" checked/><label htmlFor="radio11">Y</label>
              <input type="radio" name="radio1" id="radio12" className="adm_com_chk circle"/><label htmlFor="radio12">N</label>
            </td>
          </tr>
          <tr>
            <th>요청사항</th>
            <td><input type="text" className="input" /></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button onClick={() => setReserveEdit(false)} className="adm_com_btn blue">수정</button>
        <button onClick={() => setReserveEdit(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
    <Modal open={reserveMemo} close={() => setReserveMemo(false)} popSize="s">
      <div className="pop_tit">메모</div>
      <div className="content_container">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="20%"/><col/>
          </colgroup>
          <tbody>
          <tr>
            <th>내용</th>
            <td>
              <textarea>모공보톡스 100회 99회 210901</textarea>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button onClick={() => setReserveMemo(false)} className="adm_com_btn blue">수정</button>
        <button onClick={() => setReserveMemo(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
  </main>
  );
};

export default CancelList;
