import React from "react";

const Manual = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">수동 PUSH 관리</h2>
    <table className='adm_com_board_list type_row'>
      <colgroup>
        <col width="10%"/><col/>
      </colgroup>
      <tr>
        <th>발송방법</th>
        <td>
          <div className="input_wrap">
            <input type="checkbox" className="adm_com_chk circle" id="chk01" name="chk01" value="Y" />
            <label htmlFor="chk01">전체 회원</label>
            <input type="checkbox" className="adm_com_chk circle" id="chk02" name="chk01" value="Y" />
            <label htmlFor="chk02">개인 회원</label>
          </div>
        </td>
      </tr>
      <tr>
        <th>수신인 아이디</th>
        <td>
          <select className="select ws">
            <option value="-">-</option>
          </select>
        </td>
      </tr>
      <tr>
        <th>내용</th>
        <td>
          <textarea></textarea>
        </td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap center">
      <button className="adm_com_btn blue">발송</button>
      <button className="adm_com_btn gray">초기화</button>
    </div>
  </main>
  );
};

export default Manual;
