import React from "react";
import Paging from "../../../component/Paging";

const CouponList = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">쿠폰 발송내역</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_board_list__top">
        <div className="adm_com_date_select">
          <span className="tit">발행일 : </span>
          <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          <span className="divider"> ~ </span>
          <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          <button className="adm_com_btn black">오늘</button>
          <button className="adm_com_btn gray">어제</button>
          <button className="adm_com_btn gray">일주일</button>
          <button className="adm_com_btn gray">이번달</button>
        </div>
        <div className="adm_com_input_wrap flex_row">
          <span className="tit">쿠폰종류 : </span>
          <select className="select">
            <option value="전체">전체</option>
          </select>
        </div>
        <div className="adm_com_board_search">
          <div className="adm_com_input_wrap flex_row">
            <span className="tit">상세조회 : </span>
            <select className="select">
              <option value="전체">전체</option>
            </select>
          </div>
          <article className='search'>
            <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
            <button className="adm_com_btn blue">조회</button>
            <button className="adm_com_btn gray">초기화</button>
          </article>
        </div>
      </div>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="3%"/><col width="3%"/><col width="8%"/><col width="8%"/><col width="8%"/><col width="8%"/>
          <col width="8%"/><col width="8%"/><col width="8%"/><col width="8%"/><col width="8%"/><col width="8%"/>
        </colgroup>
        <tbody>
        <tr>
          <th><input type="checkbox" className="adm_com_chk" id="chk" name="chk" /><label htmlFor="chk"></label></th>
          <th>번호</th>
          <th>쿠폰명</th>
          <th>사용처</th>
          <th>할인금액</th>
          <th>아이디</th>
          <th>이름</th>
          <th>휴대폰</th>
          <th>이메일</th>
          <th>발행일</th>
          <th>유효기간</th>
          <th>발행인</th>
        </tr>
        <tr>
          <td><input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" /><label htmlFor="chk01"></label></td>
          <td>5</td>
          <td>생일쿠폰</td>
          <td>리프팅</td>
          <td>50,000원</td>
          <td>abc1233</td>
          <td>김올리</td>
          <td>01012451245</td>
          <td>abc1233@naver.com</td>
          <td>2023-01-01</td>
          <td>2023-01-01 ~ 2023.03-04</td>
          <td>admin</td>
        </tr>
        </tbody>
      </table>
      <Paging />
    </div>
  </main>
  );
};

export default CouponList;
