import React from "react";
import { Route, Routes } from "react-router-dom";

import CategoryManage from "../views/page/product/CategoryManage";
import Package from "../views/page/product/Package";
import PackageWrite from "../views/page/product/PackageWrite";
import EventPackage from "../views/page/product/EventPackage";
import EventPackageWrite from "../views/page/product/EventPackageWrite";
import Recommend from "../views/page/product/Recommend";
import RecommendWrite from "../views/page/product/RecommendWrite";
import PointProduct from "../views/page/product/PointProduct";

function RouterProduct() {
  return (
    <>
      <Routes>
        <Route exact path="/Category/CategoryManage" element={<CategoryManage />} />
        <Route exact path="/Category/Package" element={<Package />} />
        <Route exact path="/Category/Package/Write" element={<PackageWrite />} />
        <Route exact path="/Category/EventPackage" element={<EventPackage />} />
        <Route exact path="/Category/EventPackage/Write" element={<EventPackageWrite />} />
        <Route exact path="/Recommend" element={<Recommend />} />
        <Route exact path="/Recommend/Write" element={<RecommendWrite />} />
        <Route exact path="/PointProduct" element={<PointProduct />} />
      </Routes>
    </>
  );
}

export default RouterProduct;