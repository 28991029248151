import React from "react";
import { NavLink } from "react-router-dom";

const PushList = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">PUSH 발송 내역</h2>
    <div className="adm_com_board_list__top">
      <div className="adm_com_date_select">
        <span className="tit">기간검색 : </span>
        <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
        <span className="divider"> ~ </span>
        <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
        <button className="adm_com_btn black">조회</button>
      </div>
    </div>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="20%"/><col/><col width="10%"/><col width="10%"/>
      </colgroup>
      <tr>
        <th>메세지 발송 상황</th>
        <th>내용</th>
        <th>관리</th>
        <th>사용여부</th>
      </tr>
      <tr>
        <td>예약완료</td>
        <td class="td_left">[패키지 명] 예약이 되었습니다. 마이페이지에서 확인해보세요.</td>
        <td>
          <NavLink to='/Setting/Push/PushList/PushWrite' className="adm_com_btn blue line">내용수정</NavLink>
        </td>
        <td>
          <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
          <label htmlFor="chk01">Y</label>
        </td>
      </tr>
    </table>
  </main>
  );
};

export default PushList;
