import React, {useState} from "react";
import Paging from '../../component/Paging'
import Modal from "../../component/Modal";
import Chat from "../../component/Chat";

const Chatting = () => {
  const [chattingView, setChattingView] = useState(false)
  return (
    <main>
      <h2 className="adm_com_pg_tit">채팅문의 목록</h2>
      <div className="adm_com_pg_content">
        <div className="adm_com_board_list__top">
          <div className="adm_com_date_select">
            <span className="tit">기간설정 : </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <span className="divider"> ~ </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <button className="adm_com_btn black">오늘</button>
            <button className="adm_com_btn gray">어제</button>
            <button className="adm_com_btn gray">일주일</button>
            <button className="adm_com_btn gray">이번달</button>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">상세조회 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
            <article className='search'>
              <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
              <button className="adm_com_btn blue">조회</button>
              <button className="adm_com_btn gray">초기화</button>
            </article>
          </div>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="5%"/><col width="20%"/><col width="10%"/><col width="10%"/>
            <col width="10%"/><col width="10%"/><col width="10%"/>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>마지막 채팅내용</th>
              <th>이름</th>
              <th>닉네임</th>
              <th>채팅 시작일</th>
              <th>최근 채팅일</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5</td>
              <td>시술문의하고싶어요</td>
              <td>김올리</td>
              <td>올리5</td>
              <td>2023-01-01 15:20</td>
              <td>2023-01-01 15:20</td>
              <td><button className="adm_com_btn blue line" onClick={() => {setChattingView(true)}} >보기</button></td>
            </tr>
          </tbody>
        </table>
        <Paging />
      </div>
      <Modal open={chattingView} close={() => setChattingView(false)} popSize="m">
        <Chat />
        <div className="btn_container">
          <button onClick={() => setChattingView(false)} className="adm_com_btn s gray">닫기</button>
        </div>
      </Modal>
    </main>
  )
};

export default Chatting;
