import React from "react";
import { Route, Routes } from "react-router-dom";

import SettingManager from "../views/page/setting/manager/Index";
import SubAdmin from "../views/page/setting/manager/SubAdmin";
import SubAdminWrite from "../views/page/setting/manager/SubAdminWrite";
import PopupList from "../views/page/setting/design/PopupList";
import PopupWrite from "../views/page/setting/design/PopupWrite";
import AccountList from "../views/page/setting/account/AccountList";
import Auto from "../views/page/setting/push/Auto";
import Manual from "../views/page/setting/push/Manual";
import PushList from "../views/page/setting/push/PushList";
import PushWrite from "../views/page/setting/push/PushWrite";
import CouponIssue from "../views/page/setting/coupon/CouponIssue";
import CouponList from "../views/page/setting/coupon/CouponList";
import SearchKeyword from "../views/page/setting/search/SearchKeyword";
import Privacy from "../views/page/setting/policy/Privacy";
import Terms from "../views/page/setting/policy/Terms";
function RouterSetting() {
  return (
    <>
      <Routes>
        <Route exact path="/Manager/Admin" element={<SettingManager />} />
        <Route exact path="/Manager/SubAdmin" element={<SubAdmin />} />
        <Route exact path="/Manager/SubAdmin/Write" element={<SubAdminWrite />} />
        <Route exact path="/Design/PopupList" element={<PopupList />} />
        <Route exact path="/Design/PopupList/Write" element={<PopupWrite />} />
        <Route exact path="/Account/AccountList" element={<AccountList />} />

        <Route exact path="/Push/Auto" element={<Auto />} />
        <Route exact path="/Push/Auto/PushWrite" element={<PushWrite />} />
        <Route exact path="/Push/Manual" element={<Manual />} />
        <Route exact path="/Push/PushList" element={<PushList />} />
        <Route exact path="/Push/PushList/PushWrite" element={<PushWrite />} />

        <Route exact path="/Coupon/CouponIssue" element={<CouponIssue />} />
        <Route exact path="/Coupon/CouponList" element={<CouponList />} />
        <Route exact path="/SearchKeyword" element={<SearchKeyword />} />
        <Route exact path="/Policy/Terms" element={<Terms />} />
        <Route exact path="/Policy/Privacy" element={<Privacy />} />
      </Routes>
    </>
  );
}

export default RouterSetting;