import React from "react";
import { NavLink } from "react-router-dom";
import Paging from "../../component/Paging";

const MemberManage = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">회원 관리</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_board_list__top">
        <div className="adm_com_board_search">
          <div className="adm_com_input_wrap flex_row">
            <span className="tit">가입일 : </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <span className="divider"> ~ </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          </div>
          <article className='search end'>
            <select className="select">
              <option value="전체">전체</option>
            </select>
            <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input ws type_search" />
            <button className="adm_com_btn blue">조회</button>
            <button className="adm_com_btn gray">초기화</button>
          </article>
        </div>
      </div>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
          <col width="10%"/><col width="10%"/><col width="10%"/><col width="5%"/>
        </colgroup>
        <tbody>
        <tr>
          <th>번호</th>
          <th>아이디</th>
          <th>이름</th>
          <th>닉네임</th>
          <th>휴대폰</th>
          <th>이메일</th>
          <th>가입일</th>
          <th>최근접속일</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>abc1233</td>
          <td>김올리</td>
          <td>올리5</td>
          <td>01012451245</td>
          <td>abc1233@naver.com</td>
          <td>2023-01-01</td>
          <td>2023-01-01</td>
          <td><NavLink to="/Member/MemberManage/Detail" className="adm_com_btn blue line">상세</NavLink></td>
        </tr>
        </tbody>
      </table>
      <Paging />
    </div>
  </main>
  );
};

export default MemberManage;
