import React, {useState,useRef} from "react";
import Paging from "../../component/Paging";
import Modal from "../../component/Modal";
import { NavLink } from "react-router-dom";

const PackageWrite = () => {
  const [categoryAdd, setCategoryAdd] = useState(false);
  const [preview, setPreview] = useState(false);
  const uploadInput = useRef();
  return (
  <main>
    <h2 className="adm_com_pg_tit">일반 패키지 등록</h2>
    <div className="adm_com_pg_content">
      <table className='adm_com_board_list type_row'>
        <colgroup>
          <col width="10%"/><col/>
          <col width="10%"/><col/>
        </colgroup>
        <tbody>
          <tr>
            <th>카테고리</th>
            <td colSpan={3}>
              <select className="select">
                <option value="리프팅">리프팅</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>패키지명</th>
            <td><input type="text" defaultValue={`보톡스`}/></td>
            <th>태그</th>
            <td><input type="text" defaultValue={`바디탄력개선, 이벤트있음, 체형개선`}/></td>
          </tr>
          <tr>
            <th>부가설명</th>
            <td colSpan={3}><input type="text" defaultValue={`탄력있는 바디핏을 원하신다면, 울핏!`}/></td>
          </tr>
          <tr>
            <th>썸네일 이미지</th>
            <td colSpan={3}>
              <div className="btn_attach">
                <input type="file" className="input_file" ref={uploadInput} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 className="adm_com_pg_tit__wrap margin_top">- 일반 시술
        <button className="adm_com_btn blue" onClick={() => {setCategoryAdd(true)}}>추가</button>
      </h3>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
          <col width="10%"/><col width="10%"/>
        </colgroup>
        <tbody>
        <tr>
          <th>번호</th>
          <th>시술명</th>
          <th>표준가격</th>
          <th>할인가격</th>
          <th>할인율</th>
          <th>노출여부</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>리프팅</td>
          <td>329,000원</td>
          <td>129,000</td>
          <td>48%</td>
          <td>
            <div className="check">
              <input type="checkbox" id="chk01" className="adm_com_chk" />
              <label htmlFor="chk01"/>
            </div>
          </td>
          <td>
            <NavLink to="/Product/Category/PackageWrite" className="adm_com_btn blue line">수정</NavLink>
            <button className="adm_com_btn gray line">삭제</button>
          </td>
        </tr>
        </tbody>
      </table>
      <Paging />

      <h3 className="adm_com_pg_tit__wrap margin_top">- 상세내용</h3>
      <div className="adm_com_input_wrap">
        <div className="editor">
          내용 에디터
        </div>
      </div>
      <h3 className="adm_com_pg_tit__wrap margin_top">- 주의사항</h3>
      <div className="adm_com_input_wrap">
        <div className="editor">
          내용 에디터
        </div>
      </div>
      <h3 className="adm_com_pg_tit__wrap margin_top">- Q&A</h3>
      <div className="adm_faq_wrap">
        <div className="adm_com_input_wrap flex_row">
          <span className="tit">Q1</span>
          <input type="text" className="input" defaultValue={`질문 내용입니다.`}/>
          <button className="adm_com_btn black">저장</button>
          <button className="adm_com_btn gray">삭제</button>
        </div>
        <div className="adm_com_input_wrap flex_row">
          <span className="tit">ㄴA1</span>
          <input type="text" className="input" defaultValue={`답변 내용입니다.`}/>
          <button className="adm_com_btn black">저장</button>
          <button className="adm_com_btn gray">삭제</button>
        </div>
        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn blue">추가</button>
        </div>
      </div>

      <h3 className="adm_com_pg_tit__wrap margin_top">- 추천 패키지</h3>
      <div className="adm_com_board_list__top">
        <div className="adm_com_input_wrap flex_row">
          <span className="tit">카테고리 </span>
          <select className="select">
            <option value="일반">일반</option>
          </select>
          <select className="select">
            <option value="리프팅">리프팅</option>
          </select>
          <select className="select">
            <option value="바디슈링크">바디슈링크</option>
          </select>
        </div>
        <article className='adm_com_input_wrap flex_row'>
          <span className="tit">검색 </span>
          <div className="adm_com_board_search">
            <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input type_search" />
            <button className="adm_com_btn blue">조회</button>
            <button className="adm_com_btn gray">초기화</button>
          </div>
        </article>
      </div>

      <div className="adm_com_input_wrap flex_row flex_s">
        <div className="list_box">
          <h3 className="adm_com_pg_tit__wrap margin_top">검색결과</h3>
          <table className='adm_com_board_list scroll'>
            <colgroup>
              <col width="20%"/><col width="10%"/><col width="10%"/>
            </colgroup>
            <tr>
              <th>패키지명</th>
              <th>표준가격</th>
              <th>할인가격</th>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
            </tr>
          </table>
        </div>
        <button className="btn_add">&gt;&gt;</button>
        <div className="list_box">
          <h3 className="adm_com_pg_tit__wrap margin_top">선택한 시술</h3>
          <table className='adm_com_board_list scroll'>
            <colgroup>
              <col width="20%"/><col width="10%"/><col width="10%"/><col width="10%"/>
            </colgroup>
            <tr>
              <th>패키지명</th>
              <th>표준가격</th>
              <th>할인가격</th>
              <th>관리</th>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
            <tr>
              <td>보톡스</td>
              <td>329,000원</td>
              <td>169,000원</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
          </table>
        </div>
      </div>
      <div className="adm_com_btn_wrap bottom center">
        <button className="adm_com_btn blue">삭제</button>
        <button className="adm_com_btn blue">수정</button>
        <button className="adm_com_btn gray">취소</button>
        <button className="adm_com_btn black right" onClick={() => {setPreview(true)}}>미리보기</button>
      </div>
    </div>

    <Modal open={categoryAdd} close={() => setCategoryAdd(false)} popSize="s">
      <div className="pop_tit">일반 시술 등록</div>
      <div className="content_container">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="25%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>시술명</th>
              <td><input type="text" defaultValue={`바디슈링크 500샷`}/></td>
            </tr>
            <tr>
              <th>표준가격</th>
              <td><input type="text" defaultValue={`560,000`}/></td>
            </tr>
            <tr>
              <th>할인가격</th>
              <td><input type="text" defaultValue={`260,000`}/></td>
            </tr>
            <tr>
              <th>할인율</th>
              <td>46%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button className="adm_com_btn blue">등록</button>
        <button onClick={() => setCategoryAdd(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
    <Modal open={preview} close={() => setPreview(false)} popSize="l">
      <div className="pop_tit">미리보기</div>
      <div className="content_container">
        미리보기
      </div>
    </Modal>
  </main>
  );
};

export default PackageWrite;
