import React from "react";
import {useNavigate} from "react-router-dom"
import Paging from '../../component/Paging'

const ProductReview = () => {
  const navigate = useNavigate();
  return (
    <main>
      <h2 className="adm_com_pg_tit">시술후기 목록</h2>
      <div className="adm_com_pg_content">
        <div className="adm_com_board_list__top">
          <div className="adm_com_date_select">
            <span className="tit">기간설정 : </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <span className="divider"> ~ </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <button className="adm_com_btn black">오늘</button>
            <button className="adm_com_btn gray">어제</button>
            <button className="adm_com_btn gray">일주일</button>
            <button className="adm_com_btn gray">이번달</button>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">카테고리 : </span>
              <select className="select">
                <option value="일반">일반</option>
              </select>
              <select className="select">
                <option value="리프팅">리프팅</option>
              </select>
            </div>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">시술부위 : </span>
              
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">전체</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">눈</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">코</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">입</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">가슴</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">바디</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">안티에이징</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">안면윤곽</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">제모</label>
              <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
              <label htmlFor="chk01">기타</label>
            </div>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">상세조회 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
            <article className='search'>
              <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
              <button className="adm_com_btn blue">조회</button>
              <button className="adm_com_btn gray">초기화</button>
            </article>
          </div>
        </div>
        <div className="adm_com_btn_wrap">
          <select className="select">
            <option value="최근등록순">최근등록순</option>
          </select>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
            <col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>카테고리</th>
              <th>이름/성별</th>
              <th>닉네임</th>
              <th>시술명</th>
              <th>시술부위</th>
              <th>등록일자</th>
              <th>조회수</th>
              <th>노출여부</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5</td>
              <td>보톡스</td>
              <td>김올리/여</td>
              <td>올리5</td>
              <td>모공 보톡스</td>
              <td>입,기타</td>
              <td>2023-12-12</td>
              <td>10</td>
              <td>
                <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
                <label htmlFor="chk01">Y</label>
              </td>
              <td>
                <button onClick={() => navigate("/Board/Manage/ProductReview/Detail")} className="adm_com_btn blue line">상세</button>
                <button className="adm_com_btn gray line">삭제</button>
              </td>
            </tr>
          </tbody>
        </table>
        <Paging />
      </div>
    </main>
  )
};

export default ProductReview;
