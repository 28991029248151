import React from "react";
import {useNavigate} from "react-router-dom"
import Paging from '../../component/Paging'

const MedicalTeam = () => {
  const navigate = useNavigate();
  return (
    <main>
      <h2 className="adm_com_pg_tit">의료진 목록</h2>
      <div className="adm_com_pg_content">
        <div className="adm_com_btn_wrap end">
          <button onClick={() => navigate("/Board/Manage/MedicalTeam/Write")} className="adm_com_btn black">추가</button>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="5%"/><col width="10%"/><col width="10%"/>
            <col width="10%"/><col width="10%"/>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>직책</th>
              <th>의료진명</th>
              <th>노출여부</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>원장</td>
              <td>김원장</td>
              <td>
                <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
                <label htmlFor="chk01">Y</label>
              </td>
              <td>
                <button onClick={() => navigate("/Board/Manage/MedicalTeam/Write")} className="adm_com_btn blue line">수정</button>
                <button className="adm_com_btn gray line">삭제</button>
              </td>
            </tr>
          </tbody>
        </table>
        <Paging />
      </div>
    </main>
  )
};

export default MedicalTeam;
