import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import "./App.css";
import Header from "./views/component/Header";
import Routers from "./Routers/routers";

function App() {
  return (
    <Suspense fallback={<div>Loading... </div>}>
      <Router>
        <Header />
        <Routers />
      </Router>
    </Suspense>
  );
}

export default App;
