import React from "react";

const SearchKeyword = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit"> 검색어 관리</h2>
    <h3 className="adm_com_pg_tit__wrap margin_top">- 인기 검색 순위</h3>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>인기 검색어</th>
      </tr>
      <tr>
        <td>5</td>
        <td><input type="text" className="input" /></td>
      </tr>
    </table>
    <h3 className="adm_com_pg_tit__wrap margin_top">- 추천 검색어</h3>
    <table className='adm_com_board_list'>
      <colgroup>
        <col width="5%"/><col/>
      </colgroup>
      <tr>
        <th>번호</th>
        <th>추천 검색어</th>
      </tr>
      <tr>
        <td>5</td>
        <td><input type="text" className="input" /></td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap center">
      <button className="adm_com_btn blue">저장</button>
    </div>
  </main>
  );
};

export default SearchKeyword;
