import React from "react";
import Paging from '../../component/Paging'

const FaqCate = () => {
  return (
    <main>
      <h2 className="adm_com_pg_tit">문의 분류 관리</h2>
      <div className="adm_com_pg_content">
        <div className="adm_com_board_list__top">
          <div className="adm_com_board_search">
            <article className='search'>
              <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
              <button className="adm_com_btn blue">추가</button>
            </article>
          </div>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="10%"/><col /><col width="10%"/>
          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>분류명</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>5</td>
              <td>결제문의</td>
              <td><button className="adm_com_btn gray line">삭제</button></td>
            </tr>
          </tbody>
        </table>
        <Paging />
      </div>
    </main>
  )
};

export default FaqCate;
