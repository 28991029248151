import React from "react";
import { Route, Routes } from "react-router-dom";

import PayMentCompleteList from "../views/page/payment/CompleteList";
import PayMentCompleteView from "../views/page/payment/CompleteView";
import PayMentCancelList from "../views/page/payment/CancelList";
import PayMentCancelView from "../views/page/payment/CancelView";

function RouterPayment() {
  return (
    <>
      <Routes>
        <Route exact path="/History/Complete" element={<PayMentCompleteList />} />
        <Route exact path="/History/Complete/View" element={<PayMentCompleteView />} />
        <Route exact path="/History/Cancel" element={<PayMentCancelList />} />
        <Route exact path="/History/Cancel/View" element={<PayMentCancelView />} />
      </Routes>
    </>
  );
}

export default RouterPayment;