import React from "react";
import {Link} from "react-router-dom";
import Paging from "../../component/Paging";

const PaymentCancelList = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">결제취소</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_board_list__top">
        <div className="adm_com_date_select">
          <span className="tit">취소일 : </span>
          <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          <span className="divider"> ~ </span>
          <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
          <button className="adm_com_btn black">오늘</button>
          <button className="adm_com_btn gray">어제</button>
          <button className="adm_com_btn gray">일주일</button>
          <button className="adm_com_btn gray">이번달</button>
        </div>
        <div className="adm_com_board_search">
          <div className="adm_com_input_wrap flex_row">
            <span className="tit">상세조회 : </span>
            <select className="select">
              <option value="전체">전체</option>
            </select>
          </div>
          <article className='search'>
            <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
            <button className="adm_com_btn blue">조회</button>
            <button className="adm_com_btn gray">초기화</button>
          </article>
        </div>
      </div>
      <table className='adm_com_board_list'>
        <colgroup>
          <col width="3%"/><col width="7%"/><col width="8%"/><col width="8%"/><col width="20%" />
          <col width="8%"/><col width="8%"/><col width="8%"/><col width="8%"/>
        </colgroup>
        <tbody>
        <tr>
          <th>번호</th>
          <th>이름</th>
          <th>휴대폰</th>
          <th>예약일자</th>
          <th>결제상품</th>
          <th>취소금액</th>
          <th>취소일</th>
          <th>결제수단</th>
          <th>관리</th>
        </tr>
        <tr>
          <td>5</td>
          <td>김올리</td>
          <td>01012451245</td>
          <td>2021-08-21 15:30</td>
          <td>리프트  바디슈링크</td>
          <td>1,000,000 원</td>
          <td>2024-01-01</td>
          <td>신용카드</td>
          <td><Link to="/Payment/History/Cancel/View" className="adm_com_btn line blue">상세</Link></td>
        </tr>
        </tbody>
      </table>
      <Paging />
    </div>
  </main>
  );
};

export default PaymentCancelList;
