import React from "react";
import {useNavigate} from "react-router-dom"
import Paging from '../../component/Paging'

const Picture = () => {
  const navigate = useNavigate();
  return (
    <main>
      <h2 className="adm_com_pg_tit">전후사진 목록</h2>
      <div className="adm_com_pg_content">
        <div className="adm_com_board_list__top">
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
            <article className='search end'>
              <select className="select">
                <option value="전체">전체</option>
              </select>
              <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input ws type_search" />
              <button className="adm_com_btn blue">조회</button>
              <button className="adm_com_btn gray">초기화</button>
            </article>
          </div>
        </div>
        <div className="adm_com_btn_wrap">
          <button className="adm_com_btn gray">선택삭제</button>
          <button onClick={() => navigate("/Board/Manage/Picture/Write")} className="adm_com_btn black">추가</button>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="5%"/><col width="5%"/><col width="10%"/><col width="10%"/>
            <col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
          </colgroup>
          <thead>
            <tr>
              <th>
                <div className="check">
                  <input type="checkbox" id="chk01" className="adm_com_chk" />
                  <label htmlFor="chk01"/>
                </div>
              </th>
              <th>번호</th>
              <th>카테고리</th>
              <th>제목</th>
              <th>등록일자</th>
              <th>노출여부</th>
              <th>조회수</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="check">
                  <input type="checkbox" id="chk02" className="adm_com_chk" />
                  <label htmlFor="chk02"/>
                </div>
              </td>
              <td>1</td>
              <td>보톡스</td>
              <td>사각턱보톡스</td>
              <td>2023-12-12</td>
              <td>
                <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
                <label htmlFor="chk01">Y</label>
              </td>
              <td>10</td>
              <td>
                <button onClick={() => navigate("/Board/Manage/Picture/Write")} className="adm_com_btn blue line">수정</button>
                <button className="adm_com_btn gray line">삭제</button>
              </td>
            </tr>
          </tbody>
        </table>
        <Paging />
      </div>
    </main>
  )
};

export default Picture;
