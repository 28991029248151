import React, { useRef} from "react";

const NoticeWrite = () => {
  const uploadInput = useRef();
  return (
    <main>
      <h2 className="adm_com_pg_tit">공지사항 등록</h2>
      <div className="adm_com_pg_content">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="10%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>작성자</th>
              <td>관리자</td>
            </tr>
            <tr>
              <th>메인공지여부</th>
              <td>
                <section className="check">
                  <input type="checkbox" id="chk01" className="adm_com_chk" />
                  <label htmlFor="chk01">메인공지</label>
                </section>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td><input type="text" className="input" /></td>
            </tr>
            <tr>
              <th>이미지</th>
              <td>
                <div className="btn_attach">
                  <input type="file" className="input_file" ref={uploadInput} />
                </div>
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <div className="editor">editor</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn blue">등록</button>
          <button className="adm_com_btn gray">취소</button>
        </div>
      </div>
    </main>
  )
};

export default NoticeWrite;
