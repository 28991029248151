
import React,{useState} from 'react';
import Modal from '../../component/Modal';
import "../../../assets/scss/page/member.scss";

function Login() {
  const [modalAlert, setModalAlert] = useState(false);
  return (
    <main className='p_adm_login'>
      <div className='box'>
        <h1 className='site_name'>올리프트 Management System</h1>
        <h2 className='title'>관리자 로그인</h2>
        <div className="adm_com_input_wrap">
          <section className='sec_input'>
            <input type='text' className='input'/>
          </section>
          <section className='sec_input'>
            <input type='text' className='input'/>
          </section>
        </div>
        <p className="error_txt">ID 또는 PW가 정확하지 않습니다. <br />
          <span className="cnt">4</span>회 남았습니다.
        </p>
        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn m blue">로그인</button>
        </div>
      </div>
      {/* Modal */}
      <Modal open={modalAlert} close={() => setModalAlert(false)}>
        <div className="content_container scroll">
          <p>5회 이상 ID 또는 PW 입력오류 입니다.<br />관리자에게 문의하세요.</p>
        </div>
        <div className="btn_container">
          <button className="adm_com_btn orange xs">확인</button>
        </div>
      </Modal>
    </main>
  );
}

export default Login;
