/* eslint-disable */
import React, { useState, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
//import Logo from "../assets/img/logo.png";
function Header() {
  const pathName = useLocation().pathname;
  const [isActive, setIsActive] = useState(false);
  const [isGnbActive, setIsGnbActive] = useState(false);
  const [gnbIdx, setGnbIdx] = useState(0);
  //상단 Gnb
  const gnbData = [
    { title: "환경설정", link: "/Setting/Manager/Admin" },
    { title: "회원관리", link: "/Member/MemberManage" },
    { title: "시술관리", link: "/Product/Category/CategoryManage" },
    { title: "예약관리", link: "/Reserve/ReserveList" },
    { title: "게시판관리", link: "/Board/Manage/Notice" },
    { title: "결제내역관리", link: "/Payment/History/Complete" },
  ];
    //Left Sub Gnb Array
    const subSetting = [
      { title: "운영자 관리", link: "Setting/Manager", depth:"depth1"},
      { title: "관리자 계정 관리", link: "Setting/Manager/Admin", depth:"depth2"},
      { title: "부관리자 계정 관리", link: "Setting/Manager/SubAdmin", depth:"depth2 last"},

      { title: "디자인관리", link: "Setting/Design", depth:"depth1" },      
      { title: "팝업 관리", link: "Setting/Design/PopupList", depth:"depth2 last"},

      { title: "계좌관리", link: "Setting/Account/AccountList", depth:"depth1" },      

      { title: "PUSH관리", link: "Setting/Push", depth:"depth1"},
      { title: "자동 PUSH 관리", link: "Setting/Push/Auto" , depth:"depth2"},
      { title: "수동 PUSH 관리", link: "Setting/Push/Manual" , depth:"depth2"},
      { title: "PUSH 발송내역", link: "Setting/Push/PushList" , depth:"depth2 last"},

      { title: "쿠폰 관리", link: "Setting/Coupon", depth:"depth1"},
      { title: "쿠폰 발행 및 발행", link: "Setting/Coupon/CouponIssue" , depth:"depth2"},
      { title: "쿠폰 발행 내역", link: "Setting/Coupon/CouponList" , depth:"depth2 last"},

      { title: "검색어 관리", link: "Setting/SearchKeyword", depth:"depth1" },   

      { title: "약관 관리", link: "Setting/Policy", depth:"depth1"},
      { title: "이용약관", link: "Setting/Policy/Terms" , depth:"depth2"},
      { title: "개인정보처리방침", link: "Setting/Policy/Privacy" , depth:"depth2"},
      
    ];
    const subMember = [
      { title: "회원목록", link: "Member", depth:"depth1"},
      { title: "회원관리", link: "Member/MemberManage", depth:"depth2"},
      { title: "탈퇴 회원관리", link: "Member/MemberLeave", depth:"depth2"},
    ];
    const subProduct = [
      { title: "카테고리 관리", link: "Product/Category", depth:"depth1"},
      { title: "카테고리 관리", link: "Product/Category/CategoryManage", depth:"depth2"},
      { title: "일반 패키지", link: "Product/Category/Package", depth:"depth2"},
      { title: "이벤트 패키지", link: "Product/Category/EventPackage", depth:"depth2"},
      { title: "추천시술 관리", link: "Product/Recommend", depth:"depth1"},
      { title: "포인트 상품 관리", link: "Product/PointProduct", depth:"depth1"},
    ];
    const subReserve = [
      { title: "예약 관리", link: "Reserve", depth:"depth1"},
      { title: "예약신청/완료", link: "Reserve/ReserveList", depth:"depth2"},
      { title: "방문완료", link: "Reserve/VisitList", depth:"depth2"},
      { title: "예약취소", link: "Reserve/CancelList", depth:"depth2"},
    ];
    const subBoard = [
      { title: "게시판관리", link: "/Board/Manage", depth:"depth1"},
      { title: "공지사항", link: "/Board/Manage/Notice", depth:"depth2"},
      { title: "의료진", link: "/Board/Manage/MedicalTeam", depth:"depth2"},
      { title: "전후사진", link: "/Board/Manage/Picture", depth:"depth2"},
      { title: "시술후기", link: "/Board/Manage/ProductReview", depth:"depth2"},
      { title: "칭찬불만", link: "/Board/Manage/Review", depth:"depth2"},
      { title: "채팅문의", link: "/Board/Manage/Chatting", depth:"depth2"},

      { title: "1:1문의 관리", link: "/Board/Faq/", depth:"depth1"},
      { title: "문의 분류 관리", link: "/Board/Faq/FaqCate", depth:"depth2"},
      { title: "문의관리", link: "/Board/Faq/Faq", depth:"depth2"},
    ];
    const subPayment = [
      { title: "결제내역", link: "Payment/", depth:"depth1"},
      { title: "결제완료", link: "Payment/History/Complete", depth:"depth2"},
      { title: "결제취소", link: "Payment/History/Cancel", depth:"depth2"},
    ]
  const renderGnb = useCallback(() => {
    return gnbData.map((v, idx) => {
      return (
        <NavLink
          to={v.link}
          key={idx}
          onClick={() => {
            setIsGnbActive(false);
            setGnbIdx(idx + 1);
          }}
          className={`link ${
            isGnbActive || gnbIdx === idx + 1 ? "active" : ""
          }`}
        >
          {v.title}
        </NavLink>
      );
    });
  }, [gnbIdx]);
  const renderSub = useCallback(() => {
    let arr = [];
    if (pathName.startsWith("/Login")) {
      return <></>;
    } else if (pathName.startsWith("/Setting")) {
      arr = subSetting;
    } else if (pathName.startsWith("/Member")) {
      arr = subMember;
    } else if (pathName.startsWith("/Product")) {
      arr = subProduct;
    } else if (pathName.startsWith("/Reserve")) {
      arr = subReserve;
    } else if (pathName.startsWith("/Board")) {
      arr = subBoard;
    } else if (pathName.startsWith("/Payment")) {
      arr = subPayment;
    } else {
      return <></>;
    }
    return (
      <div className="gnb_sub_wrap">
        <NavLink to="/" className="logo">
          <h1>올리프트</h1>
        </NavLink>
        <div className="list">
          {arr.map((v, idx) => {
            return (
              <NavLink
                to={v.link}
                key={idx}
                onClick={() => setIsActive(false)}
                className={`link ${v.depth} ${isActive ? "active" : ""}`}
              >
                {v.title}
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }, [pathName]);

  return (
    <header
      className={`adm_head  ${
        pathName.includes("/Login") ? `isHideGnb` : ``
      }`}
    >
      <section className="gnb_wrap">
        <div className="adm_com_center_wrap">
          <section className="gnb_list">{renderGnb()}</section>
          <section className="menu_list">
            <NavLink to="/" className="link" onClick={() => {setGnbIdx(0);}}>관리자 정보</NavLink>
            <NavLink to="/" className="link" onClick={() => {setGnbIdx(0);}}>웹사이트이동</NavLink>
            <NavLink to="/" className="link" onClick={() => {setGnbIdx(0);}}>로그아웃</NavLink>
            <NavLink to="/Login" className="link" onClick={() => {setGnbIdx(0);}}>로그인</NavLink>
          </section>
        </div>
      </section>
      {renderSub()}
    </header>
  );
}

export default Header;
