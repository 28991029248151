import React from "react";

const FaqWrite = () => {
  return (
    <main>
      <h2 className="adm_com_pg_tit">문의 답변</h2>
      <div className="adm_com_pg_content">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="10%"/><col/>
            <col width="10%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>등록일</th>
              <td colSpan={3}>2023-01-01</td>
            </tr>
            <tr>
              <th>분류</th>
              <td colSpan={3}>
                <select className="select ws">
                  <option value="결제문의">결제문의</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                김올리
              </td>
              <th>닉네임</th>
              <td>
                올리5
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>쿠폰을 받지 못했어요</td>
            </tr>
            <tr>
              <th>문의내용</th>
              <td colSpan={3}>안녕하세요. 쿠폰을 받지 못했어요</td>
            </tr>
            <tr>
              <th>답변</th>
              <td colSpan={3}>
                <textarea className="textarea"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn blue">등록</button>
          <button className="adm_com_btn gray">취소</button>
        </div>
      </div>
    </main>
  )
};

export default FaqWrite;
