import React, { useState } from "react";
import Modal from "../../component/Modal";

const CategoryManage = () => {
  const [categoryAdd, setCategoryAdd] = useState(false)
  return (
  <main>
    <h2 className="adm_com_pg_tit">카테고리 목록</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_board_list__top">
        <div className="adm_com_board_search">
          <div className="adm_com_input_wrap flex_row">
            <select className="select">
              <option value="일반">일반</option>
            </select>
          </div>
          <article className='search'>
            <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input type_search" />
            <button className="adm_com_btn black">추가</button>
          </article>
        </div>
      </div>
      <div className="adm_com_input_wrap flex_row">
        <div className="list_box">
          <h3 className="adm_com_pg_tit__wrap margin_top">- 일반 카테고리</h3>
          <table className='adm_com_board_list'>
            <colgroup>
              <col width="10%"/><col /><col width="20%"/><col width="20%"/>
            </colgroup>
            <tr>
              <th>번호</th>
              <th>카테고리명</th>
              <th>등록패키지</th>
              <th>관리</th>
            </tr>
            <tr>
              <td>5</td>
              <td>보톡스</td>
              <td>12</td>
              <td>
                <button className="adm_com_btn blue line" onClick={() => {setCategoryAdd(true)}}>수정</button>
                <button className="adm_com_btn gray line">삭제</button>
              </td>
            </tr>
          </table>
        </div>
        <div className="list_box">
          <h3 className="adm_com_pg_tit__wrap margin_top">- 이벤트 카테고리</h3>
          <table className='adm_com_board_list'>
            <colgroup>
              <col width="10%"/><col /><col width="20%"/><col width="20%"/>
            </colgroup>
            <tr>
              <th>번호</th>
              <th>카테고리명</th>
              <th>등록패키지</th>
              <th>관리</th>
            </tr>
            <tr>
              <td>5</td>
              <td>보톡스</td>
              <td>12</td>
              <td>
                <button className="adm_com_btn blue line" onClick={() => {setCategoryAdd(true)}}>수정</button>
                <button className="adm_com_btn gray line">삭제</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <Modal open={categoryAdd} close={() => setCategoryAdd(false)} popSize="s">
      <div className="pop_tit">카테고리 수정</div>
      <div className="content_container">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="25%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>종류</th>
              <td>
                <select className="select">
                  <option value="일반">일반</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>카테고리명</th>
              <td><input type="text" defaultValue={`보톡스`}/></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn_container">
        <button className="adm_com_btn blue">수정</button>
        <button onClick={() => setCategoryAdd(false)} className="adm_com_btn gray">취소</button>
      </div>
    </Modal>
  </main>
  );
};

export default CategoryManage;
