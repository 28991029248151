import React from "react";
const ReviewDetail = () => {
  return (
    <main>
      <h2 className="adm_com_pg_tit">칭찬불만 상세</h2>
      <div className="adm_com_pg_content">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="10%"/><col/>
            <col width="10%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>등록일</th>
              <td colSpan={3}>2021-08-10</td>
            </tr>
            <tr>
              <th>칭찬/불만</th>
              <td>칭찬</td>
              <th>유형</th>
              <td>직원에 대한 의견</td>
            </tr>
            <tr>
              <th>이름</th>
              <td>김올리</td>
              <th>이름</th>
              <td>올리5</td>
            </tr>
            <tr>
              <th>의견내용</th>
              <td colSpan={3}>   상담받으러 갔는데 직원분이 친절하게 대해주셨습니다. 칭찬합니다.</td>
            </tr>
            <tr>
              <th>메모</th>
              <td colSpan={3}><textarea className="textarea"></textarea></td>
            </tr>
          </tbody>
        </table>

        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn blue">수정</button>
          <button className="adm_com_btn gray">취소</button>
        </div>
      </div>
    </main>
  )
};

export default ReviewDetail;
