import React from "react";
import { Route, Routes } from "react-router-dom";

import Notice from "../views/page/board/Notice";
import NoticeWrite from "../views/page/board/NoticeWrite";
import MedicalTeam from "../views/page/board/MedicalTeam";
import MedicalTeamWrite from "../views/page/board/MedicalTeamWrite";
import Picture from "../views/page/board/Picture";
import PictureWrite from "../views/page/board/PictureWrite";
import ProductReview from "../views/page/board/ProductReview";
import ProductReviewDetail from "../views/page/board/ProductReviewDetail";
import Review from "../views/page/board/Review";
import ReviewDetail from "../views/page/board/ReviewDetail";
import Chatting from "../views/page/board/Chatting";
import FaqCate from "../views/page/board/FaqCate";
import Faq from "../views/page/board/Faq";
import FaqWrite from "../views/page/board/FaqWrite";

function RouterBoard() {
  return (
    <>
      <Routes>
        <Route exact path="/Manage/Notice" element={<Notice />} />
        <Route exact path="/Manage/Notice/Write" element={<NoticeWrite />} />
        <Route exact path="/Manage/MedicalTeam" element={<MedicalTeam />} />
        <Route exact path="/Manage/MedicalTeam/Write" element={<MedicalTeamWrite />} />
        <Route exact path="/Manage/Picture" element={<Picture />} />
        <Route exact path="/Manage/Picture/Write" element={<PictureWrite />} />
        <Route exact path="/Manage/ProductReview" element={<ProductReview />} />
        <Route exact path="/Manage/ProductReview/Detail" element={<ProductReviewDetail />} />
        <Route exact path="/Manage/Review" element={<Review />} />
        <Route exact path="/Manage/Review/Detail" element={<ReviewDetail />} />
        <Route exact path="/Manage/Chatting" element={<Chatting />} />
        <Route exact path="/Faq/FaqCate" element={<FaqCate />} />
        <Route exact path="/Faq/Faq" element={<Faq />} />
        <Route exact path="/Faq/Faq/Write" element={<FaqWrite />} />
        
     
      </Routes>
    </>
  );
}

export default RouterBoard;