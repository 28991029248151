import React from "react";
import {Link} from "react-router-dom";

const PaymentCompleteView = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">결제완료</h2>
    <table className='adm_com_board_list type_row'>
      <colgroup>
        <col width="10%"/><col width="40%"/>
        <col width="10%"/><col width="40%"/>
      </colgroup>
      <tr>
        <th>아이디</th>
        <td colSpan={3}>abc123</td>
      </tr>
      <tr>
        <th>이름</th>
        <td>김올리</td>
        <th>성별</th>
        <td>여</td>
      </tr>
      <tr>
        <th>닉네임</th>
        <td>올리5</td>
        <th>생년월일</th>
        <td>1985년 08월 03일</td>
      </tr>
      <tr>
        <th>휴대폰</th>
        <td colSpan={3}>010-0000-0000</td>
      </tr>
      <tr>
        <th>이메일</th>
        <td colSpan={3}>dhffl@naver.com</td>
      </tr>
      <tr>
        <th>결제상품</th>
        <td colSpan={3}>dhffl@naver.com</td>
      </tr>
      <tr>
        <th>예약일자</th>
        <td colSpan={3}>2023-01-01 15:30</td>
      </tr>
      <tr>
        <th>결제수단</th>
        <td colSpan={3}>신용카드</td>
      </tr>
      <tr>
        <th>결제일</th>
        <td colSpan={3}>2023-01-01</td>
      </tr>
    </table>
    <h3 className="adm_com_pg_tit__wrap margin_top">- 시술 예약 내역</h3>
    <table className="adm_com_board_list">
      <colgroup><col width="3%"/><col width="3%"/><col width="20%"/><col width="8%"/><col width="8%"/></colgroup>
      <thead>
        <tr>
          <th><input type="checkbox" className="adm_com_chk" id="chk01"/><label htmlFor="chk01"></label></th>
          <th>번호</th>
          <th>예약시술명</th>
          <th>결제금액</th>
          <th>취소하기</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><input type="checkbox" className="adm_com_chk" id="chk0101"/><label htmlFor="chk0101"></label></td>
          <td>5</td>
          <td>모공보톡스 1부위(국산)</td>
          <td>200,000원</td>
          <td>-</td>
        </tr>
      </tbody>
    </table>
    <table className="adm_com_total_list">
      <tr>
        <th>총 시술금액</th>
        <td>1,000,000 원</td>
        <th>쿠폰사용금액</th>
        <td>1,000,000 원</td>
        <th>최종 결제금액</th>
        <td>1,000,000 원</td>
      </tr>
    </table>
    <div className="adm_com_btn_wrap bottom center">
      <button className="adm_com_btn blue">취소하기</button>
      <Link to="/Payment/History/Complete" className="adm_com_btn black">목록</Link>
    </div>
  </main>
  );
};

export default PaymentCompleteView;
