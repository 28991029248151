import React from "react";
import Paging from '../../component/Paging'
import {NavLink} from "react-router-dom"

const Faq = () => {
  return (
    <main>
      <h2 className="adm_com_pg_tit">문의목록</h2>
      <div className="adm_com_pg_content">
        
      <div className="adm_com_board_list__top">
          <div className="adm_com_date_select">
            <span className="tit">기간설정 : </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <span className="divider"> ~ </span>
            <input type="date" className="adm_com_datepicker" placeholder="2022. 01. 01" />
            <button className="adm_com_btn black">오늘</button>
            <button className="adm_com_btn gray">어제</button>
            <button className="adm_com_btn gray">일주일</button>
            <button className="adm_com_btn gray">이번달</button>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">문의분류 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">처리상태 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
          </div>
          <div className="adm_com_board_search">
            <div className="adm_com_input_wrap flex_row">
              <span className="tit">상세조회 : </span>
              <select className="select">
                <option value="전체">전체</option>
              </select>
            </div>
            <article className='search'>
              <input type="search" placeholder='검색할 내용을 입력해 주세요.' className="input s type_search" />
              <button className="adm_com_btn blue">조회</button>
              <button className="adm_com_btn gray">초기화</button>
            </article>
          </div>
        </div>
        <div className="adm_com_btn_wrap">
          <button className="adm_com_btn gray">선택삭제</button>
        </div>
        <table className='adm_com_board_list'>
          <colgroup>
            <col width="5%"/><col width="5%"/><col width="10%"/><col width="20%"/>
            <col width="10%"/><col width="10%"/><col width="10%"/><col width="10%"/>
          </colgroup>
          <thead>
            <tr>
              <th>
                <div className="check">
                  <input type="checkbox" id="chk01" className="adm_com_chk" />
                  <label htmlFor="chk01"/>
                </div>
              </th>
              <th>번호</th>
              <th>분류</th>
              <th>제목</th>
              <th>이름</th>
              <th>닉네임</th>
              <th>등록일자</th>
              <th>처리상태</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="check">
                  <input type="checkbox" id="chk01" className="adm_com_chk" />
                  <label htmlFor="chk01"/>
                </div>
              </td>
              <td>5</td>
              <td>결제문의</td>
              <td><NavLink to="/Board/Faq/Faq/Write">문의합니다</NavLink></td>
              <td>김올리</td>
              <td>김올리5</td>
              <td>2023-01-01</td>
              <td className="adm_com_red_txt">답변대기</td>
            </tr>
            <tr>
              <td>
                <div className="check">
                  <input type="checkbox" id="chk01" className="adm_com_chk" />
                  <label htmlFor="chk01"/>
                </div>
              </td>
              <td>4</td>
              <td>결제문의</td>
              <td><NavLink to="/Board/Faq/Faq/Write">문의합니다</NavLink></td>
              <td>김올리</td>
              <td>김올리5</td>
              <td>2023-01-01</td>
              <td className="adm_com_blue_txt">답변완료</td>
            </tr>
          </tbody>
        </table>
        <Paging />
      </div>
    </main>
  )
};

export default Faq;
