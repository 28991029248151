import React, { useRef} from "react";

const MedicalTeamWrite = () => {
  const uploadInput = useRef();
  return (
    <main>
      <h2 className="adm_com_pg_tit">의료진 등록</h2>
      <div className="adm_com_pg_content">
        <table className='adm_com_board_list type_row'>
          <colgroup>
            <col width="10%"/><col/>
          </colgroup>
          <tbody>
            <tr>
              <th>직책</th>
              <td><input type="text" className="input" /></td>
            </tr>
            <tr>
              <th>의료진명</th>
              <td><input type="text" className="input" /></td>
            </tr>
            <tr>
              <th>썸네일 이미지</th>
              <td>
                <div className="btn_attach">
                  <input type="file" className="input_file" ref={uploadInput} />
                </div>
              </td>
            </tr>
            <tr>
              <th>약력</th>
              <td>
                <div className="editor">editor</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="check">
          <input type="checkbox" className="adm_com_chk" id="chk01" name="chk01" value="Y" />
          <label htmlFor="chk01">노출여부</label>
        </div>
        <div className="adm_com_btn_wrap center">
          <button className="adm_com_btn blue">등록</button>
          <button className="adm_com_btn gray">취소</button>
        </div>
      </div>
    </main>
  )
};

export default MedicalTeamWrite;
