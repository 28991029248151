import React from "react";
import { NavLink } from "react-router-dom";

const Recommend = () => {
  return (
  <main>
    <h2 className="adm_com_pg_tit">추천시술 목록</h2>
    <div className="adm_com_pg_content">
      <div className="adm_com_btn_wrap right">
        <NavLink to="/Product/Recommend/Write" className="adm_com_btn blue">변경</NavLink>
      </div>

      <table className='adm_com_board_list'>
        <colgroup>
          <col width="5%"/><col width="10%"/><col width="10%"/><col width="10%"/>
        </colgroup>
        <tbody>
        <tr>
          <th>번호</th>
          <th>패키지명</th>
          <th>표준가격</th>
          <th>할인가격</th>
        </tr>
        <tr>
          <td>5</td>
          <td>리프팅</td>
          <td>329,000원</td>
          <td>129,000</td>
        </tr>
        </tbody>
      </table>
    </div>
  </main>
  );
};

export default Recommend;
